<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card flat dense>
      <v-toolbar :color="options.color" dense flat>
        <v-toolbar-title
          :class="[{ 'white--text': options.color }, options.titleClass]"
          >{{ title }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon :dark="!!options.color" @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-show="!!message" :class="options.messageClass">{{
        message
      }}</v-card-text>
      <v-card-actions>
        <slot name="buttons" :cancel="cancel" :agree="agree">
          <v-btn large outlined depressed color="grey" @click.native="cancel">{{
            options.cancelBtnText
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            large
            depressed
            :color="options.agreeBtnColor"
            @click.native="agree"
            >{{ options.agreeBtnText }}</v-btn
          >
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {},
  }),

  computed: {
    defaultOptions() {
      return {
        color: '',
        width: 500,
        titleClass: '',
        messageClass: '',
        cancelBtnText: this.$t('BUTTON.CANCEL'),
        agreeBtnText: this.$t('BUTTON.OK'),
        agreeBtnColor: 'primary',
      };
    },
  },

  mounted() {
    this.options = this.defaultOptions;
  },

  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.defaultOptions, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
